import React from 'react';
import { graphql, Link } from 'gatsby'
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import IndexStickyHeader from '../components/IndexStickyHeader';
import MdCardList from '../components/MdCardsList';
import InitialSplashText from '../components/InitialSplashText';
import SplitSectionOurWork from '../components/SplitSectionOurWork';
import { BrightLightCard } from '../components/BrightLightCard';
import ResponsiveArrow from '../components/ResponsiveArrow';
import TabTitle from '../components/meta/tabTitle';
import { useMediaQuery } from 'react-responsive';

const IndexPage = ({data}) => {
  const isMobilePanelView = useMediaQuery({
    query: '(max-device-width: 600px)'
  });

  const indexDataNodes = data.allMarkdownRemark.group[0].nodes

  return (
    <Layout>
      <TabTitle title="Digital Financial" />
      { !isMobilePanelView && <IndexStickyHeader nodes={indexDataNodes}/> }
      <InitialSplashText/>
      <ResponsiveArrow scrollTo={'#split-section-our-work-container'}/>
      <SplitSectionOurWork />

      {false && <section className="my-4 container mx-auto">
        <div className="h-full sm:grid sm:grid-rows-1 sm:grid-rows-1 sm:gap-2 sm:grid-cols-3">
          <BrightLightCard
            color="bg-brightlightgray"
            title="Digital Customer Engagement"
            subtitle="Focus on delivering value to your clients"
            to="https://www.google.com"
          />
          <BrightLightCard
            color="bg-brightlightpink"
            title="Universal Secure Access"
            subtitle="Convenience and peace of mind for you and your clients"
            to="https://www.google.com"
          />
          <BrightLightCard
            color="bg-brightlightbeige"
            title="Accelerated Business Development"
            subtitle="Reliable foundation for your vision"
            to="https://www.google.com"
          />
        </div>
      </section>
      }

      <MdCardList nodes={indexDataNodes}/>

      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Ready to build the future of financial services?</h3>
        <p className="mt-8 text-xl font-light">
        </p>

        <p className="mt-8">
          <Link to="/contact">
          <Button size="xl">Get In Touch to Learn More</Button>
          </Link>
        </p>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
    query MyQuery {
        allMarkdownRemark(filter: {frontmatter: {page: {eq: "index"}}}, sort: {fields: frontmatter___order}) {
          group(field: frontmatter___page) {
          nodes {
            frontmatter {
              order
              page
              title
              type
              image
              imagePosition
              imageWidth
              imageBelowHeader
              linkSectionWithImageBelow
              extraContainerClass
              extraImageClass
            }
          html
        }
      }
    }
  }
  `
