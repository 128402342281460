import React from 'react';

const InitialSplashText = () => (
  <section className="py-10 xl:pt-20 xl:pb-16 xxl:py-24 container mx-auto flex justify-end font-bold">
    <h1 className="splash-big-text xl:pl-4">
      Cloud Data Platform for Financial Services
    </h1>
  </section>
);

export default InitialSplashText;
