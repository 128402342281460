import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const SplitSectionOurWork = () => (
  <div id="split-section-our-work-container"
       className="py-16 lg:mb-6 bg-splashblue text-white">
    <section className="split-section-our-work-label container mx-auto flex justify-start">
      <h1 className="splash-big-text splash-block-header left-auto pl-0">
        Build better financial services faster, with less risk.
      </h1>
    </section>

    <SplitSectionBase
      id="split-section-our-work"
      primarySlot={OurWorkLabel()}
    />
    <section className="container mx-auto flex justify-start">
      <PillBoxes/>
    </section>
  </div>
);

const OurWorkLabel = () => (
  <>
    <div className="text-2xl font-thin">
      Asset managers, asset owners — pensions, endowments, foundations, and family offices — and other financial institutions
      use the DiFi Data Management Platform (DMP) to create customized financial services easily, without the delays,
      cost overruns, and limitations caused by incompatible data and applications.  The DMP automatically connects tools
      and teams within institutions, and it enables seamless back office coordination between asset owners, asset managers,
      technology providers, financial institutions, services vendors— everyone necessary to build the best financial solutions.
    </div>
  </>
);

const PillBoxes = () =>
  (
    <div>
      <AnchorLink offset='-47' href="#model"><button className="pill-button">Portfolio Hierarchy</button></AnchorLink>
      <AnchorLink offset='-47' href="#model"><button className="pill-button">Asset Detail</button></AnchorLink>
      <AnchorLink offset='-47' href="#ingestion-integration"><button className="pill-button">FX Rates</button></AnchorLink>
      <AnchorLink offset='-47' href="#model"><button className="pill-button">Daily Positions</button></AnchorLink>
      <Link to="/analytics"><button className="pill-button">Investor Profiling</button></Link>
      <AnchorLink offset='-47' href="#model"><button className="pill-button">Ledger</button></AnchorLink>
      <AnchorLink offset='-47' href="#ingestion-integration"><button className="pill-button">Open Contracts & Liabilities</button></AnchorLink>
      <AnchorLink offset='-47' href="#ingestion-integration"><button className="pill-button">Corporate Actions</button></AnchorLink>
      <AnchorLink offset='-47' href="#ingestion-integration"><button className="pill-button">Benchmarks & Constituents</button></AnchorLink>
    </div>
  );

// We need to overide a bunch of container styles but dont want to remove the `SplitSection`
// entirely

export const SplitSectionBase = ({ id, primarySlot, secondarySlot }) => (
  <section id={id} className="py-10 font-light" >
    <div className="container mx-auto flex flex-col lg:flex-row my-4">
      <div className="lg:pr-10">{primarySlot}</div>
    </div>
  </section>
);

export default SplitSectionOurWork;

